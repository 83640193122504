<template>
    <Container :loading="loading" :empty="!detail.title">


        <div class="detail">
            <div class="title">{{ detail.title }}</div>
            <div class="around data">
                <div>时间：{{ detail.publish_at | timeTrans("yyyy-MM-dd") }}</div>
                <div>来源：{{ detail.author }}</div>
            </div>
            <div class="content">


                <div v-show="images.length > 0">

                    <div class="image-wrapper">
                        <img style="margin-bottom: 24px" v-if="images[activeIndex]" :src="images[activeIndex].url"
                            alt="">

                        <div class="center clickable arrow prev" @click="prev">
                            <i class="iconfont icon-arrow-right"></i>
                        </div>
                        <div class="center clickable arrow next" @click="next">
                            <i class="iconfont icon-arrow-right"></i>
                        </div>
                    </div>

                    <div class="swiper images">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item, index) in images" :key="item.url">
                                <img :src="item.url" alt="" @click="setActive(index)">
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
                <div v-html="centerVideoContent"></div>
            </div>

        </div>

    </Container>


</template>

<script>
import { fetchDetail } from '@/api/article'

export default {
    data() {
        return {
            loading: false,
            detail: {},
            activeIndex: 0,
        }
    },

    watch: {
        detail() {

            if (Array.isArray(this.detail.attach) && this.detail.attach.length > 0) {
                       new window.Swiper(".images", {
                         slidesPerView: 3,
                spaceBetween: 12,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
            }

 



        }
    },

    computed: {
        content() {
            return this.detail.content
                ? this.detail.content.replace(
                    /<img/g,
                    "<img style='width: 100%'"
                )
                : "";
        },
        centerVideoContent() {
            return this.content.replace(/<video/g, "<video style='display: block;margin: 0 auto'")
        },
        images () {
            return Array.isArray(this.detail.attach) ? this.detail.attach : []
        }
    },
    mounted() {
        const { query } = this.$route;
        const { code } = query || {};
        this.loading = true;
        fetchDetail({ code }).then(res => {
            if (res) {
                // console.log(res)
                this.detail = res;
            }
        }).finally(() => {
            this.loading = false;
        })
    },

    methods: {
        setActive(index) {
            this.activeIndex = index;
        },
        prev() {
            if (this.activeIndex > 0) { 
                this.activeIndex--;
            } else {
                this.$message.error("没有更多了")
            }
        },
        next() {
            if (this.activeIndex < this.images.length - 1) {
                this.activeIndex++
            } else {
                this.$message.error("没有更多了")
            }
        }
    }




}
</script>

<style lang="less" scoped>
.detail {
    background-color: #fff;
    padding: 12px;

    .title {
        font-size: 20px;
        color: #333333;
        font-weight: bold;
        text-align: center;
        line-height: 45px;
        background-color: #f8f8f8;
        margin-bottom: 12px;
    }

    .data {
        font-size: 13px;
        color: #989898;
        margin-bottom: 40px;
    }

    .content {
        p {
            text-indent: 2em;
        }

        img {
            width: 100%;
        }
    }
}

      .swiper {
        margin-bottom: 24px;
        width: 100%;

                   .swiper-slide img {
                    cursor: pointer;
          width: 100%;
        display: block;
      }
      }

      .image-wrapper {
        position: relative;

        img {
            height: 618px;
            object-fit: cover;
        }

          .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 00, 0, .8);
        color: #fff;
        height: 48px;
        width: 24px;
        font-weight: bold;
        font-size: 24px;
    }
    .prev {
        left: 0;
        transform: translateY(-50%) rotate(-180deg);
    }
    .next {
        right: 0;
    }
      }


</style>